import React, { useState } from 'react';

function Newsletter() {

  const [email, setEmail] = useState();

  const send = () => {
    if (email) {
      const content = {
        "content": email
      }
      fetch('https://discord.com/api/webhooks/914737856709275669/NcnsjnGTsrhsdeBJcZT8TWI6lxGD5PrhXHKIB9MINtoZcJ8sagLsJ_Ta6QiAWr-t5-vv', {
        method: 'POST',
        body: JSON.stringify(content),
        headers: {
          'Content-type': 'application/json'
        }})
        .then(res => {
          setEmail("")
        })
      }
    }


  return (
    <section id="safely2">
      <div className="max-w-6xl mx-auto px-4 sm:px-6 m-10">
        <div className="pb-12 md:pb-20">

          {/* CTA box */}
          <div className="relative bg-gray-900 rounded-lg py-10 px-8 md:py-16 md:px-12 shadow-2xl overflow-hidden" data-aos="zoom-y-out">

            {/* Background illustration */}
            <div className="absolute right-0 bottom-0 pointer-events-none hidden lg:block" aria-hidden="true">
              <svg width="428" height="328" xmlns="http://www.w3.org/2000/svg">
                <defs>
                  <radialGradient cx="35.542%" cy="34.553%" fx="35.542%" fy="34.553%" r="96.031%" id="ni-a">
                    <stop stopColor="#DFDFDF" offset="0%" />
                    <stop stopColor="#4C4C4C" offset="44.317%" />
                    <stop stopColor="#333" offset="100%" />
                  </radialGradient>
                </defs>
                <g fill="none" fillRule="evenodd">
                  <circle fill="url(#ni-a)" cx="276" cy="237" r="200" />
                </g>
              </svg>
            </div>

            <div className="relative flex flex-col lg:flex-row justify-between items-center">

              {/* CTA content */}
              <div className="text-center lg:text-left lg:max-w-xl">
                <h3 className="h3 text-white mb-2 text-3xl font-bold leading-tight">Nous rejoindre !</h3>
                <p className="text-gray-300 text-lg mb-6">Si le projet vous intéresse et que vous souhaitez dialoguer avec nous, alors n’hésitez pas à vous inscrire à la NewsLetter !</p>

                {/* CTA form */}
                <form className="w-full lg:w-auto">
                  <div className="flex flex-col sm:flex-row justify-center max-w-xs mx-auto sm:max-w-md lg:mx-0">
                    <input value={email} onChange={e => setEmail(e.target.value)} type="email" className="w-full appearance-none bg-gray-800 border border-gray-700 focus:border-gray-600 rounded-sm px-4 py-3 mb-2 sm:mb-0 sm:mr-2 text-white placeholder-gray-500" placeholder="Votre e-mail" aria-label="Your email…" />
                    <a className="btn text-white bg-blue-600 hover:bg-blue-700 shadow rounded-full py-3 px-6" href="#0" onClick={send}>S'abonner</a>
                  </div>
                  {/* Success message */}
                  {/* <p className="text-sm text-gray-400 mt-3">Thanks for subscribing!</p> */}
                </form>
              </div>

            </div>

          </div>

        </div>
      </div>
    </section>
  );
}

export default Newsletter;
