import React, { useState } from 'react';
import safelyback from './../../assets/safelyback.png';
import Footer from './../../components/Footer';
import Newsletter from './../../components/NewsLetter';
import Features from './../../components/Features';
import logo from './../../assets/logo2.png';
import { HashLink as Link } from 'react-router-hash-link';
import Logo from './../../assets/logo.png';
import Team from '../../components/Team';
import Timeline from '../../components/Timeline';
import Simulation from '../../components/Simulation';

const LandingPageLayout = ({
  ...otherProps
}) => {

  const [active, setActive] = useState(false);

  const onClick = () => {
    setActive(!active);
  };

  return (
    <div className="min-h-full bg-gray-100 font-body">
      <div className="h-screen flex flex-col">
        <header className="bg-white relative">
          <div className="max-w-7xl mx-auto flex items-center justify-between p-2.5">
            <div className="w-14">
              <img src={Logo} className="w-full" alt="" />
            </div>
            <div onClick={onClick} className={`md:hidden uppercase`}>
              Menu
            </div>
            <nav className={`${!active && 'hidden'} absolute flex flex-col bg-white top-full w-full left-0 z-20 md:static md:w-auto md:flex`}>
              <ul className="md:flex-row md:flex">
                <li className="list-none md:mr-5">
                  <Link smooth to="/Home#safely1" className="flex w-full text-base uppercase hover:text-red-600 cursor-pointer pt-2.5 px-2.5">
                    Qu'est-ce que Safely ?
                  </Link>
                </li>
                <li className="list-none md:mr-5">
                  <Link smooth to="/Home#safely3" className="flex w-full text-base uppercase hover:text-red-600 cursor-pointer pt-2.5 px-2.5">
                    Qui sommes nous ?
                  </Link>
                </li>
                <li className="list-none md:mr-5">
                  <Link smooth to="/Home#safely4" className="flex w-full text-base uppercase hover:text-red-600 cursor-pointer pt-2.5 px-2.5">
                    Timeline du projet
                  </Link>
                </li>
                <li className="list-none md:mr-5">
                  <Link smooth to="/Home#safely6" className="flex w-full text-base uppercase hover:text-red-600 cursor-pointer pt-2.5 px-2.5">
                    Simulation
                  </Link>
                </li>
                <li className="list-none md:mr-5">
                  <Link scroll={(el) => el.scrollIntoView({ behavior: 'smooth' })} to="/Home#safely2" className="flex w-full text-base uppercase hover:text-red-600 cursor-pointer pt-2.5 px-2.5">
                    Nous rejoindre
                  </Link>
                </li>
                <li className="list-none md:mr-5">
                  <Link to="safely.apk" target="_blank" className="flex w-full text-base uppercase hover:text-red-600 cursor-pointer pt-2.5 px-2.5" download> 
                    Télécharger l'application
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </header>
        <div className="relative flex items-center justify-center h-full bg-cover bg-center bg-fixed bg-no-repeat"
              style={{ backgroundImage: `url(${safelyback})`}}>
          <img src={logo} className="w-full" alt="" />
          <div className="z-0 absolute -top-0 -left-0 h-full w-full bg-black opacity-40"/>
        </div>
      </div>
      <Features />
      <Team />
      <Timeline />
      <Simulation />
      <Newsletter />
      <Footer />
    </div>
  );
};

export default LandingPageLayout;
