import React from 'react';

const Footer = ({
  ...otherProps
}) => { 
  return (
    <footer className="bg-gray-800">
      <div className="w-full max-w-7xl mx-auto py-14 px-2.5">
        <div className="relative flex justify-center mt-4 lg:mt-0">
        <span className="absolute top-5 left-10 text-base text-gray-600">
          © Safely 2022, All rights reserved 
        </span>
        <div className="absolute right-10">
        <button className="btn btn-success" onClick={()=> window.open("https://www.facebook.com/safewayfrance", "_blank")}>
            <svg
              fill="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              className="w-12 h-12 text-blue-600"
              viewBox="0 0 24 24"
            >
              <path
                d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"
              ></path>
            </svg>
          </button>
        <button className="ml-3" onClick={()=> window.open("https://www.linkedin.com/company/safeway-france/", "_blank")}>
            <svg
              fill="currentColor"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="0"
              className="w-12 h-12 text-blue-500"
              viewBox="0 0 24 24"
            >
              <path
                stroke="none"
                d="M16 8a6 6 0 016 6v7h-4v-7a2 2 0 00-2-2 2 2 0 00-2 2v7h-4v-7a6 6 0 016-6zM2 9h4v12H2z"
              ></path>
              <circle cx="4" cy="4" r="2" stroke="none"></circle>
            </svg>
          </button>
      </div>
      </div>
      </div>
    </footer>
  );
};

export default Footer;
