import React, { useState } from 'react';

const TimelineNode = ({
  date,
  name,
  index,
  setDescIndex
}) => {
  const currentDate = Date.now();
  const nodeDate = (new Date(date)).getTime();

  const getDescriptionStyle = (odd) => {
    return (index % 2 === odd ? "inline-block" : "hidden");
  };

  if (nodeDate - currentDate > 0) {
    return (
      <div>
        <span className={`${getDescriptionStyle(1)} text-gray-500 absolute text-sm font-bold md:w-32 lg:w-60`}
          style={{ transform: "translate(-50%, -50%)" }}>{name}</span>
        <div className="h-8 mx-2 flex my-12 cursor-pointer" onMouseEnter={() => setDescIndex(index)} onMouseLeave={() => setDescIndex(-1)}>
          <span className="w-8 lg:w-12 h-1 my-auto bg-gray-500"></span>
          <span className="w-10 lg:w-14 h-6 my-auto rounded-full bg-gray-500"></span>
          <span className="w-8 lg:w-12 h-1 my-auto bg-white bg-gray-500"></span>
        </div>
        <span className={`${getDescriptionStyle(0)} text-gray-500 absolute text-sm font-bold md:w-32 lg:w-60`}
          style={{ transform: "translate(-50%, -50%)" }}>{name}</span>
    </div>
    );
  }

  return (
    <div>
      <span className={`${getDescriptionStyle(1)} text-white absolute text-sm font-bold md:w-32 lg:w-60`}
        style={{ transform: "translate(-50%, -50%)" }}>{name}</span>
      <div className="h-8 mx-2 flex my-12 cursor-pointer" onMouseEnter={() => setDescIndex(index)} onMouseLeave={() => setDescIndex(-1)}>
        <span className="w-8 lg:w-12 h-1 my-auto bg-white"></span>
        <span className="w-10 lg:w-14 h-6 my-auto rounded-full bg-white"></span>
        <span className="w-8 lg:w-12 h-1 my-auto bg-white"></span>
      </div>
      <span className={`${getDescriptionStyle(0)} text-white absolute text-sm font-bold md:w-32 lg:w-60`}
        style={{ transform: "translate(-50%, -50%)" }}>{name}</span>
    </div>
  );
};

const Timeline = ({
  ...otherProps
}) => {
  const [descriptionIndex, setDescriptionIndex] = useState(-1);

  const descriptions = [
    "Naissance du projet - phase d'idéation.",
    "Début du développement et formation de l'équipe.",
    "Structuration et organisation de l'équipe.",
    "Développement intensif du projet.",
    "Acquisition d'utilisateurs et lancement de la bêta.",
    "Prise en compte des retours de bêta et finalisation du projet.",
    "Lancement définitif du projet."
  ];

  return (
    <section className="relative" id="safely4">

      <div className="relative mt-16 bg-gray-900 text-center pt-16 pb-40" aria-hidden="true">

        <div className="max-w-3xl mx-auto text-center pb-8 text-white">
          <h2 className="h2 mb-4 text-5xl font-extrabold leading-tight tracking-tighter">Timeline du projet</h2>
        </div>

        <div className="inline-flex mx-auto px-auto pt-8">
          <TimelineNode index={0} date="2021-03-30" name="Creation" setDescIndex={setDescriptionIndex} />
          <TimelineNode index={1} date="2021-06-30" name="Test & Learn" setDescIndex={setDescriptionIndex} />
          <TimelineNode index={2} date="2021-10-30" name="Management & Processes" setDescIndex={setDescriptionIndex} />
          <TimelineNode index={3} date="2022-01-30" name="Fast Forward" setDescIndex={setDescriptionIndex} />
          <TimelineNode index={4} date="2022-05-30" name="Beta & Growth Hacking" setDescIndex={setDescriptionIndex} />
          <TimelineNode index={5} date="2022-09-30" name="Consolidation" setDescIndex={setDescriptionIndex} />
          <TimelineNode index={6} date="2022-12-30" name="Launch & Metrics" setDescIndex={setDescriptionIndex} />
        </div>

        <div hidden={descriptionIndex < 0} className="absolute text-white w-full mx-auto font-bold" style={{ transform: "translate(0%, 350%)" }}>
          {descriptions[descriptionIndex]}
        </div>

      </div>

    </section>
  );
}

export default Timeline;
