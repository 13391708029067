import { createContext, useContext, useState } from "react";
import Stepper from "./Stepper";
import StepperControl from "./StepperControl";


const StepperContext = createContext({ userData: "", setUserData: null });

function UseContextProvider({ children }) {
    const [userData, setUserData] = useState("");
  
    return (
      <StepperContext.Provider value={{ userData, setUserData }}>
        {children}
      </StepperContext.Provider>
    );
  }

function useStepperContext() {
    const { userData, setUserData } = useContext(StepperContext);
  
    return { userData, setUserData };
  }

function Profile() {
    const { userData, setUserData } = useStepperContext();
  
    const handleChange = (e) => {
      const { name, value } = e.target;
      setUserData({ ...userData, [name]: value });
    };
  
    return (
      <div className="flex flex-col ">
        <div className="mx-2 w-full flex-1">
          <div required className="mt-3 h-6 text-xs font-bold uppercase leading-8 text-gray-500">
            Nom et Prénom
          </div>
          <div className="my-2 flex rounded border border-gray-200 bg-white p-1">
            <input
              onChange={handleChange}
              value={userData["name"] || ""}
              name="name"
              placeholder="Nom et Prénom"
              className="w-full appearance-none p-1 px-2 text-gray-800 outline-none"
            />
          </div>
        </div>
        <div className="mx-2 w-full flex-1">
          <div required className="mt-3 h-6 text-xs font-bold uppercase leading-8 text-gray-500">
            Email
          </div>
          <div className="my-2 flex rounded border border-gray-200 bg-white p-1">
            <input
              onChange={handleChange}
              value={userData["Email"] || ""}
              name="Email"
              placeholder="Email"
              type="Email"
              className="w-full appearance-none p-1 px-2 text-gray-800 outline-none"
            />
          </div>
        </div>
      </div>
    );
  }

function Details() {
    const { userData, setUserData } = useStepperContext();
  
    const handleChange = (e) => {
      const { name, value } = e.target;
      setUserData({ ...userData, [name]: value });
    };
    return (
      <div className="flex flex-col ">
        <div className="w-full mx-2 flex-1">
          <div className="font-bold h-6 mt-3 text-gray-500 text-xs leading-8 uppercase">
            Addresse
          </div>
          <div className="bg-white my-2 p-1 flex border border-gray-200 rounded">
            <input
              onChange={handleChange}
              value={userData["Addresse"] || ""}
              name="Addresse"
              placeholder="Addresse"
              className="p-1 px-2 appearance-none outline-none w-full text-gray-800"
            />
          </div>
        </div>
        <div className="w-full mx-2 flex-1">
          <div className="font-bold h-6 mt-3 text-gray-500 text-xs leading-8 uppercase">
            Ville
          </div>
          <div className="bg-white my-2 p-1 flex border border-gray-200 rounded">
            <input
              onChange={handleChange}
              value={userData["Ville"] || ""}
              name="Ville"
              placeholder="Ville"
              type="text"
              className="p-1 px-2 appearance-none outline-none w-full text-gray-800"
            />
          </div>
        </div>
        <div className="w-full mx-2 flex-1">
          <div className="font-bold h-6 mt-3 text-gray-500 text-xs leading-8 uppercase">
            Nom du commerce
          </div>
          <div className="bg-white my-2 p-1 flex border border-gray-200 rounded">
            <input
              onChange={handleChange}
              value={userData["commerce"] || ""}
              name="commerce"
              placeholder="Nom du commerce"
              type="text"
              className="p-1 px-2 appearance-none outline-none w-full text-gray-800"
            />
          </div>
        </div>
      </div>
    );
  }

  
  function Product() {
      const { userData, setUserData } = useStepperContext();
      userData["Notification"] = false
      const handleChange = (e) => {
          const { name, value } = e.target;
          setUserData({ ...userData, [name]: value });
        };

        const handleCheck = () => {
            if(userData["Notification"] === false) {
                userData["Notification"] = true;
            } else {
                userData["Notification"] = false;
            }
          };
        return (
            <div className="flex flex-col ">
      <div className="w-full mx-2 flex-1">
        <div className="font-bold h-6 mt-3 text-gray-500 text-xs leading-8 uppercase">
          Nombre d'utilisateurs moyen
        </div>
        <div className="bg-white my-2 p-1 flex border border-gray-200 rounded">
          <input
            onChange={handleChange}
            value={userData["userNbr"] || ""}
            name="userNbr"
            type="Number"
            placeholder="Nombre d'utilisateurs moyen"
            className="p-1 px-2 appearance-none outline-none w-full text-gray-800"
            />
        </div>
      </div>
      <div class="flex items-center">
        <input onChange={handleCheck} id="Redirection" type="checkbox" value={userData["Redirection"] || false} class="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"></input>
        <label for="Redirection" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Redirection Boutique</label>
    </div>
    <div class="flex items-center">
        <input onChange={handleCheck} id="Notification" type="checkbox" value={userData["Notification"] || false} class="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"></input>
        <label for="Notification" class="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Notification ciblé</label>
    </div>
    </div>
  );
}

function Final() {
    const { userData } = useStepperContext();
    return (
      <div className="container md:mt-10">
        <div className="flex flex-col items-center">
          <div className="wrapper">
            <svg
              className="checkmark"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 52 52"
            >
              <circle
                className="checkmark__circle"
                cx="26"
                cy="26"
                r="25"
                fill="none"
              />
              <path
                className="checkmark__check"
                fill="none"
                d="M14.1 27.2l7.1 7.2 16.7-16.8"
              />
            </svg>
          </div>
  
          <div className="mt-3 text-xl font-semibold uppercase text-blue-500">
            Bravo !
          </div>
          <div className="mt-3 text-lg font-semibold text-gray-500">
            Votre simulation a été crée, elle vous coutera { userData["Notification"] === true ? ((userData["userNbr"] * 0.006 * 270) + (userData["userNbr"] * 0.9 * 3) + (userData["userNbr"] * 0.75 * 2)).toFixed(2) : ((userData["userNbr"] * 0.006 * 270) + (userData["userNbr"] * 0.9 * 3)).toFixed(2) } € avec les paramètres que vous avez défini.
          </div>
        </div>
      </div>
    );
  }  
function Simulation() {
  const [currentStep, setCurrentStep] = useState(1);

  const steps = [
    "Informations personelles",
    "Details du commerce",
    "Votre personnalisation",
    "Finalisation",
  ];

  const displayStep = (step) => {
    switch (step) {
      case 1:
        return <Profile />;
      case 2:
        return <Details />;
      case 3:
        return <Product />;
      case 4:
        return <Final />;
      default:
    }
  };

  const handleClick = (direction) => {
    let newStep = currentStep;

    direction === "next" ? newStep++ : newStep--;
    newStep > 0 && newStep <= steps.length && setCurrentStep(newStep);
  };

  return (
    <section id="safely6">
        <div className="pt-8">
    <div className="mx-auto rounded-2xl bg-white pb-2 shadow-xl md:w-1/2">
        <div className="max-w-3xl mx-auto text-center pb-8">
          <h2 className="h2 mb-4 text-5xl font-extrabold leading-tight tracking-tighter">Simulation de campagne publicitaire</h2>
        </div>
      <div className="horizontal container mt-5 ">
        <Stepper steps={steps} currentStep={currentStep} />

        <div className="my-10 p-10 ">
          <UseContextProvider>{displayStep(currentStep)}</UseContextProvider>
        </div>
      </div>

      
        <StepperControl
          handleClick={handleClick}
          currentStep={currentStep}
          steps={steps}
        />
      
    </div>
    </div>
    </section>
  );
}

export default Simulation;
