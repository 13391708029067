import React from "react";
import antoinezimmermann from './../../assets/team_members/antoinezimmermann.jpg';
import hoanggiangvo from './../../assets/team_members/hoanggiangvo.jpg';
import ibrahimakgul from './../../assets/team_members/ibrahimakgul.jpg';
import louischristner from './../../assets/team_members/louischristner.jpg';
import mathieuhoulle from './../../assets/team_members/mathieuhoulle.jpg';
import theolocati from './../../assets/team_members/theolocati.jpg';

const TeamMember = ({
  name,
  description,
  imageUrl
}) => {
  return (
    <div className="relative flex flex-col items-center p-6 bg-white rounded shadow-xl w-full h-full">
      <img className="w-24 h-24 rounded-full p-1 -mt-1 mb-2" src={imageUrl} alt=""></img>
      <h4 className="text-xl font-bold leading-snug tracking-tight mb-1">{name}</h4>
      <p className="text-gray-600 text-center">{description}</p>
    </div>
  );
};

const Team = ({
  ...otherProps
}) => {
  return (
    <section className="relative" id="safely3">
      <div className="pt-8">

        <div className="max-w-3xl mx-auto text-center pb-8">
          <h2 className="h2 mb-4 text-5xl font-extrabold leading-tight tracking-tighter">Qui sommes nous ?</h2>
        </div>

        <div className="max-w-sm mx-auto grid gap-6 md:grid-cols-2 lg:grid-cols-3 items-start md:max-w-2xl lg:max-w-5xl">

          <TeamMember
            name="Mathieu Houlle"
            description="Développeur Back End & DevOps - Chef de projet"
            imageUrl={mathieuhoulle}
          />

          <TeamMember
            name="Antoine Zimmermann"
            description="Développeur Back End - Lead développeur"
            imageUrl={antoinezimmermann}
          />

          <TeamMember
            name="Ibrahim Akgül"
            description="Développeur Back End"
            imageUrl={ibrahimakgul}
          />

          <TeamMember
            name="Hoang Giang Vo"
            description="Développeur Mobile"
            imageUrl={hoanggiangvo}
          />

          <TeamMember
            name="Louis Christner"
            description="Développeur Front End"
            imageUrl={louischristner}
          />

          <TeamMember
            name="Théo Locati"
            description="Développeur Front End"
            imageUrl={theolocati}
          />

        </div>
      </div>
    </section>
  );
};

export default Team;