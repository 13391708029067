import React from 'react';
import Cards from '../../components/Cards';

const HomePage = ({
  ...otherProps
}) => {
  return <Cards />;
};

export default HomePage;
